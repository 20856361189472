import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.user.pipe(
      take(1),
      map(user => {
        return user && user.roles.admin ? true : false;
      }),
      tap(isAdmin => {
        if (!isAdmin) {
          console.error('Access denied - Admins only');
          this.router.navigate(['/user-profile']);
        } else {
          console.log('admin access complete.');
          console.log('isAdmin', isAdmin);
        }
      })
    );

  }
}