import { AppService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModelEditDialogComponent } from './model-edit-dialog/model-edit-dialog.component';

@Component({
  selector: 'app-model-manager',
  templateUrl: './model-manager.component.html',
  styleUrls: ['./model-manager.component.scss']
})
export class ModelManagerComponent implements OnInit {
  specs: any;
  constructor(
    private appService: AppService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.appService.getSpecs()
      .subscribe(specs => this.specs = specs);
  }


  openDialog(data): void {

    const targetRef = this.specs[data];
    const dialogRef = this.dialog.open(ModelEditDialogComponent, {
      width: '98vw',
      data: targetRef
    });

  }





}
