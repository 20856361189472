import { CupModel } from './../model/cup';
import { AppService } from './../app.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';


@Injectable()
export class SearchService {
  cups;

  constructor(private appService: AppService) {
    this.appService
      .getData()
      .subscribe(cups => {
        this.cups = _.filter(cups, { state: 'published' } );
      });
  }
  search(term: string): Observable<CupModel[]> {
    const isHangul = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힝]/; // check language

    let result;
    if (isHangul.test(term)) {
      result =  this.cups
      .filter((cup: CupModel) => {
        return (term)
          ? cup.name.ko.toLowerCase().indexOf(term) !== -1
          : Observable.of<CupModel[]>([]);
      });
    } else {
      result =  this.cups
      .filter((cup: CupModel) => {
        return (term)
          ? cup.name.en.toLowerCase().indexOf(term) !== -1
          : Observable.of<CupModel[]>([]);
      });
    }

    return Observable.of(result);
  }
}
