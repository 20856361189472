//// Angular
import { Component, OnInit, AfterViewInit, Inject, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
//// Angular Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// Chips
import { MatChipInputEvent } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { AppService } from './../../app.service';
import { CupModel } from './../../model/cup';



@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit, AfterViewInit, AfterViewChecked {
  updateSource: CupModel;

  specs;
  models;


  //// Chips ////

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];



  constructor(
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.updateSource = this.data;
    this.appService.getSpecs().subscribe(specs => this.specs = specs);
  }

  ngAfterViewInit() { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  updateData() {
    this.appService.updateCup(this.data.id, this.updateSource)
      .then(() => this.dialogRef.close())
      .catch(err => console.error(err));
  }

  // deep copy 해결하지 못하면 사용 불가
  duplicateData() {
    const copyData = this.data;
    copyData.id = '';

    // add fields (about duplicate)
    copyData.createdAt = new Date();
    copyData.updatedAt = new Date();
    this.appService.create(copyData)
      .then(res => {
        this.appService.updateCup(res.id, { id: res.id });
        this.dialogRef.close();
      })
      .catch(err => console.error(err));
  }

  deleteData() {
    this.appService.deleteCup(this.data.id);
    this.dialogRef.close();
  }


  // select element 선택시 데이터셋에 반영
  updateByOptionClick(selectedOption: any, category: any) {
    this.updateSource[category] = selectedOption.value;
    this.updateSource[category + '_en'] = selectedOption.value_en;
  }

  updateByOption(selectedOption: any, category: string, isSingleAttr?: any) {
    // option이 단일 값인 경우(officialsite = 'https://stuckyi.studio')
    if(isSingleAttr) {
      if(category === 'state') {
        this.data[category] = selectedOption.en;
      } else {
        this.data[category] = selectedOption.ko; // material
      } 
    } else {
      this.data[category] = { ko: selectedOption.ko, en: selectedOption.en };
    }
  }



  addColor(event: MatChipInputEvent, category: string): void {
    const input = event.input;
    const value = event.value;

    this.data.createdAt = new Date(); // 생성일시 추가

    if (this.data.colorNames === null) {
      this.data.colorNames = ['clear'];
      this.data.colorCodes = ['#ffffff'];
    }

    const targetArr = (category === 'name')
      ? this.data.colorNames
      : this.data.colorCodes;

    // Add our Cup
    if ((value || '').trim()) {
      targetArr.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeColor(color: any, category: string): void {
    console.log('removeColor!');
    const targetArr = (category === 'name')
      ? this.data.colorNames
      : this.data.colorCodes;
    const index = targetArr.indexOf(color);

    if (index >= 0) {
      targetArr.splice(index, 1);
    }
  }

  clone(obj) {
    if (obj === null || typeof (obj) !== 'object') {
      return obj;
    }
    const copy = obj.constructor();
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        // copy[attr] = obj[attr];          // shallow copy
        copy[attr] = this.clone(obj[attr]); // deep copy
      }
    }
    return copy;
  }


  checkValue() {
    const source = this.updateSource;
    for (const attr in source) {
      if (source.hasOwnProperty(attr)) {
        if (source[attr] === '') {
          delete source[attr];
        }
      }
    }
  }


}


