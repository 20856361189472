import { CupModel } from './../model/cup';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import * as _ from 'lodash';
import { globalAnimations } from 'app/util/social/animations';

@Component({
  selector: 'app-cups',
  templateUrl: './cups.component.html',
  styleUrls: ['./cups.component.scss'],
  animations: [globalAnimations]
})
export class CupsComponent implements OnInit {
  isInit = false;
  cups;

  sortMethod = 'desc';
  sortKey = 'totalLength';

  // hover
  curHoverCupIndex;


  constructor(
    private appService: AppService,
    private router: Router) { }

  ngOnInit() {
    this.isInit = true;
    this.appService.getData()
      .subscribe(cups => {
        this.cups = _.sortBy(cups, [function(o){ return o.name.en; }]);
      });
  }
  
  cupUp(index: number) {
    this.curHoverCupIndex = index;
  }

  getBg(url: string) {
    return 'url("/images/cups/' + url + '");';
  }

}
