import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule, MatFormFieldModule, MatIconModule } from '../../../node_modules/@angular/material';
import { ContributingComponent } from './contributing.component';


const CONTRIBUTING_MATERIALMODULES = [
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule
];
@NgModule({
  imports: [
    CommonModule,
    CONTRIBUTING_MATERIALMODULES
  ],
  declarations: [ContributingComponent]
})
export class ContributingModule { }
