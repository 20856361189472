import { NouisliderModule } from 'ng2-nouislider';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FilterMaterialModule } from './filter-material.module';
import { FilterComponent } from './filter.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FilterMaterialModule,
    NouisliderModule
  ],
  declarations: [FilterComponent]
})
export class FilterModule { }
