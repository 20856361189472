import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { AppService } from './../app.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit, AfterViewInit {
  cups;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;

  // array 순서에따라 column 순서가 결정된다.
  displayedColumns = ['Name'];

  refLinks = [
    { 
      name: `멘스트루얼 컵스`, 
      name_en: `MenstrualCups Wordpress`,
      summary: ``, 
      url: `https://menstrualcups.wordpress.com`
    },
    { 
      name: `풋 어 컵 인 잇`,
      name_en: `Put A Cup In It`,
      summary: ``, 
      url: `https://putacupinit.com`
    },

    { 
      name: `멘스트루얼 컵 리뷰즈`,
      name_en: `menstrualcupreviews`,
      summary: ``, 
      url: `menstrualcupreviews.net/`
    },
    { 
      name: `afriska`, 
      name_en: `afriska`,
      summary: ``, 
      url: `https://www.afriska.ch`
    },
   
    
  ]
  //// for html
  // 데이터 수집 항목
  collectItems = [
    '제품명',
    '제품군',
    '사이즈',
    '국가',
    '색상',
    '가격',
    '소재',
    '최대 지름',
    '길이(전체, 컵, 손잡이)',
    '용량(전체, 공기구멍)',
    '제품 사진',
    '공식 웹사이트 주소',
    '공기구멍 여부',
    '손잡이 미끄럼 방지 여부'
  ];



  isProductList = false;
  isRefSiteList = false;
  isRefItemList = false;

  isBasisCountry = false;
  isBasisColor = false;
  isBasisMaterial = false;
  isBasisPrice = false;

  isMaxDiameter = false;
  isLength = false;
  isCapacity = false;
  isFrontView = false;

  constructor(private appService: AppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.appService.getData()
      .subscribe(cups => {
        this.cups = cups;
        this.updateDataSource();
      });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  updateDataSource() {
    this.cups = _.sortBy(this.cups, ['name']['ko']);
    this.dataSource = new MatTableDataSource(this.cups);
    this.dataSource.paginator = this.paginator;
  }


  // table
  trackByUid(index, item) {
    return item.uid;
  }

  

  onMoreview(elementName: string) {
    if (elementName === 'product-list') { this.isProductList = !this.isProductList; }
    if (elementName === 'ref-site-list') { this.isRefSiteList = !this.isRefSiteList; }
    if (elementName === 'ref-item-list') { this.isRefItemList = !this.isRefItemList; }

    if (elementName === 'basis-country') { this.isBasisCountry = !this.isBasisCountry; }
    if (elementName === 'basis-color') { this.isBasisColor = !this.isBasisColor; }
    if (elementName === 'basis-material') { this.isBasisMaterial = !this.isBasisMaterial; }
    if (elementName === 'basis-price') { this.isBasisPrice = !this.isBasisPrice; }

    if (elementName === 'max-diameter') { this.isMaxDiameter = !this.isMaxDiameter; }
    if (elementName === 'length') { this.isLength = !this.isLength; }
    if (elementName === 'capacity') { this.isCapacity = !this.isCapacity; }
    if (elementName === 'front-view') { this.isFrontView = !this.isFrontView; }
  }


  openWindow(url: string) {
    const urls = 'http://' + url;
    window.open(urls);
  }
}
