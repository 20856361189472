import { AuthService, User } from './../../core/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user;
  email;
  password;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  login() {
    this.authService.emailLogin(this.email, this.password);
  }
  ngOnInit() {
    this.authService.user.subscribe(user => this.user = user);
  }

}
