import { AppService } from './app.service';
import { CupModel } from './model/cup';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';

import { trigger, animate, style, transition, state, keyframes } from '@angular/animations';

import { Observable } from 'rxjs/Rx';
// search bar
import {FormControl} from '@angular/forms';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('dynamicNavClass', [
      state('init', style({ display: 'none' })),
      state('nav-on', style({ display: 'flex' })),
      state('nav-off', style({ display: 'none' })),
      transition('nav-on => nav-off',
        animate('0.4s cubic-bezier(1,.015,.295,1.225)', keyframes([
          style({ transform: 'translate(0, 0)', offset: 0 }),
          style({ transform: 'translate(0, -100%)', offset: 1 })
        ]))
      ),
      transition('nav-off => nav-on',
        animate('0.48s cubic-bezier(1,.015,.295,1.225)', keyframes([
          style({ transform: 'translate(0, -100%)', offset: 0 }),
          style({ transform: 'translate(0, 0)', offset: 1 })
        ])),
      )
  ])]
})
export class AppComponent implements OnInit {

  //// serach with mat-toolbar
  isSearch = false;
  cupCtrl: FormControl;
  searchedCups: Observable<CupModel[]>;

  cups: CupModel[];

  ////
  @ViewChild('nav', { static: false }) nav: ElementRef;
  currentUrl = '';
  isBackBtn = false;


  isNav = true;
  dynamicNavClass = 'nav-on';

  m_NavStartY = 500; // 모바일 네비게이션 on 기준점
  m_isNav = true;
  m_dynamicClass = 'init';

  scrollY = { prev: 0 };
  constructor(
    private router: Router,
    private appService: AppService
  ) {
    this.cupCtrl = new FormControl();
    this.searchedCups = this.cupCtrl.valueChanges
      .pipe(
      startWith(''),
      map(cup => cup ? this.searchCups(cup) : this.cups.slice()))
  }
  searchCups(name: any) {
    // 한,영 모두 지원하는 형태로 개선 필요
    return this.cups.filter(cup => cup.name.ko.indexOf(name) === 0);
  }

  ngOnInit() {
    this.appService
    .getData()
    .subscribe(cups => {
      this.cups = cups;
    })

    this.registerScrollEvent();
    this.registerRouterCD();
  }

  onSearch() {
    this.isSearch = !this.isSearch;
  }


  gotoDetail(cupImgUrls: string) {
    this.isSearch = false;
    this.cupCtrl.reset();
    const link = ['/detail', cupImgUrls];
    this.router.navigate(link)
      .then(nav => console.log(nav))
      .catch(err => console.log('catch', err));
  }
  registerRouterCD() {
    this.router.events.subscribe(event => {
      if (event.constructor.name === 'NavigationEnd') {
        const curUrl = this.router.url;
        this.isBackBtn = (curUrl === '/cups') ? false : true;
        this.currentUrl = curUrl.split('/')[1];
      }
    });
  }

  registerScrollEvent() {

    const scrollTop$ = Observable.fromEvent(window, 'scroll');
    const scrollFn$ = scrollTop$
      .map((ev: any) => {
        const currentY = ev.target.scrollingElement.scrollTop;
        if (this.scrollY.prev > currentY) {
          this.scrollY.prev = currentY;
          return true;
        }
        this.scrollY.prev = currentY;
        return false;
      });
    const m_scrollFn$ = scrollTop$
      .map((ev: any) => {
        const currentY = ev.target.scrollingElement.scrollTop;
        return (currentY >= this.m_NavStartY) ? true : false;
      });
    // mobile nav controll
    const m_checkScrollChanged$ = m_scrollFn$.distinctUntilChanged();
    m_checkScrollChanged$.subscribe(val => {
      if (this.currentUrl !== 'cups') {
        this.m_isNav = true;
        this.m_dynamicClass = 'nav-on';
      } else {
        this.m_isNav = val;
        this.m_dynamicClass = (val === true) ? 'nav-on' : 'nav-off';
      }
    });

    // pc nav controll
    const distinctUntilChangedScrollTop$ = scrollFn$.distinctUntilChanged();
    distinctUntilChangedScrollTop$.subscribe(val => {
        this.isNav = val;
        this.dynamicNavClass = (val === true) ? 'nav-on' : 'nav-off';
    });
  }

  gotoTop() {
    window.scrollTo(0, 0);
  }

}
