import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CupDetailComponent } from './cup-detail.component';
import { CupSliderComponent } from './cup-slider/cup-slider.component';


import {
  MatTooltipModule,
  // MatToolbarModule,
  MatIconModule,
  MatListModule,
  // MatButtonModule,
  MatDividerModule,
  // MatAutocompleteModule,
  // MatInputModule,
  // MatOptionModule,
  // MatFormFieldModule,
  // MatSidenavModule,
  
} from '@angular/material';
import { RouterModule } from '@angular/router';

export const CUP_DETAIL_COMPONENTS = [
  CupDetailComponent,
  // CupSliderComponent
];
export const CUP_DETAIL_MODULES = [
  MatIconModule,
  MatListModule,
  MatDividerModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [CUP_DETAIL_COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    CUP_DETAIL_MODULES
  ],
  exports: [
    CUP_DETAIL_COMPONENTS
  ]
})
export class CupDetailModule { }
