
import { CupModel } from './../model/cup';
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';

import { AppService } from '../app.service';
import { globalAnimations } from 'app/util/social/animations';


export interface SpecBtn {
  name: string;
  en: string;
  alias: string;
}
@Component({
  selector: 'app-cup-detail',
  templateUrl: './cup-detail.component.html',
  styleUrls: ['./cup-detail.component.scss'],
  animations: [globalAnimations]
})
export class CupDetailComponent implements OnInit {
  isInit = false;
  cup;

  //// concat
  currentColor = { name: 'clear', code: '#ffffff' };

  // image
  imgPath = '../../assets/images';
  imgType = '_total';
  imgColor: any;

  // modal
  isModal = false;
  isNonslip = false;

  currentSpecBtn: SpecBtn;
  specBtns: SpecBtn[] = [
    { name: '전체 길이', en: 'totalLength', alias: 'total' },
    { name: '컵 길이', en: 'bodyLength', alias: 'body' },
    { name: '손잡이 길이', en: 'stemLength', alias: 'stem' },
    { name: '최대 지름', en: 'externalDiameter', alias: 'diameter' },
  ];

  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    
    this.currentSpecBtn = this.specBtns[0];

    let cupImgUrls;

    
    this.activatedRoute.params.subscribe(params => {
      this.isInit = false;
      cupImgUrls = params['cupImgUrls'];
      // console.log(`cupImgUrls: ${cupImgUrls}`);
      // 여기서 추가적인 로직을 수행할 수 있습니다.
      this.appService
      .getData()
      .subscribe(cups => {
        const curIndex = _.findIndex(cups, cup => {
          return cup.imgUrls === cupImgUrls;
        });
        this.cup = cups[curIndex];
        this.currentColor['name'] = this.cup.colorNames[0];
        this.currentColor['code'] = this.cup.colorCodes[0];

        // console.log(this.cup);
        this.isInit = true;
      });
    });
    window.scrollTo(0, 0);
  }

  
  
  openWindow(url: string) {
    window.open(url);
  }


  onModal(cup: CupModel) {
    this.isNonslip = (cup.stemIsNonslip === 'Y') ? true : false;
    this.isModal = !this.isModal;
  }


  // spec 영역에서 마우스 오버시, cup-detail의 viewer 이미지를 변경
  changeView(viewType: string) {
    this.imgType = viewType;
  }
  setSpecBtn(specBtn: SpecBtn) {
    this.currentSpecBtn = specBtn;
  }

  // spec의 color-chip을 클릭하면, viewer내 이미지를 해당 color의 이미지로 바꾸기위함.
  changeColor(index: number) {
    this.currentColor['name'] = this.cup.colorNames[index];
    this.currentColor['code'] = this.cup.colorCodes[index];
  }


  


}
