import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Injectable, OnInit, AfterViewInit } from '@angular/core';
import { Cup, CupModel } from './model/cup';
import { CUPS } from './data/cups';

@Injectable()
export class AppService implements OnInit, AfterViewInit {
  cups: Cup[];
  selectedIndex: number;

  // afs
  cupCollection: AngularFirestoreCollection<CupModel>;
  cupsAdmin: AngularFirestoreCollection<CupModel>;
  
  cupSpecs: AngularFirestoreDocument<any>; // need to type


  constructor(private afs: AngularFirestore) {
    this.cups = CUPS;
    // this.cupCollection = this.afs.collection<CupModel>('cups', ref => ref.orderBy('name', 'asc'));
    this.cupCollection = this.afs.collection<CupModel>('cups', ref => ref.where('state', '==', 'published'));
    this.cupsAdmin = this.afs.collection<CupModel>('cups');
    this.cupSpecs = this.afs.doc<any>('specs/spec');
  }

  ngOnInit() {
    this.cups.sort((a, b) => { return a.totalLength - b.totalLength; });
    console.log(this.cupCollection);
  }

  ngAfterViewInit() {
  }

  // spec service

  getSpecs() {
    return this.cupSpecs.valueChanges();
  }
  updateSpec(data: any) {
    return this.cupSpecs.update(data);
  }






  // admin service
  getAdminData() {
    return this.cupsAdmin.valueChanges();
  }

  getAdminSnapshot() {
    return this.cupsAdmin.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          return { id: a.payload.doc.id, ...a.payload.doc.data() };
        });
      });
  }



  getData() {
    return this.cupCollection.valueChanges();
  }

  getSnapshot() {
    return this.cupCollection.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          return { id: a.payload.doc.id, ...a.payload.doc.data() };
        });
      });
  }
  getCupById(id: any) {
    return this.afs.doc<any>('cups/' + id); // type 지정 하자
  }

  create(data: any) {
    return this.cupsAdmin.add(data);
  }

  updateCup(id, data) {
    data.updatedAt = new Date();
    return this.getCupById(id).update(data);
  }
  deleteCup(id) {
    return this.getCupById(id).delete();
  }
  setCupWithMerge(id, data) {
    return this.getCupById(id).set(data, { merge: true });
  }





  /// cupskr

  getSelectedIndex() {
    return this.selectedIndex;
  }

  getCup(id: number) {
    return this.cups.find(cup => { return cup.id === id; });
  }


  getCups() {
    return this.cups;
  }

  getCupList(index: number) {
    if (index === 1) {
      return this.cups.slice(0, 8);
    } else if (index === 2) {
      return this.cups.slice(8, 16);
    } else if (index === 3) {
      return this.cups.slice(16, 24);
    } else if (index === 4) {
      return this.cups.slice(24, 32);
    }
    console.log('getCupList Error but all data return.');
    return this.cups;
  }
}
