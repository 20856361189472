export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyD2MR7DGmoK1IC1u1_RVF2xrCg96vuhTwo',
    authDomain: 'collection-17b31.firebaseapp.com',
    databaseURL: 'https://collection-17b31.firebaseio.com',
    projectId: 'collection-17b31',
    storageBucket: 'collection-17b31.appspot.com',
    messagingSenderId: '479631612149',
    appId: '1:479631612149:web:4a3f14cc2e8d7544'
  }
};
