import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contributing',
  templateUrl: './contributing.component.html',
  styleUrls: ['./contributing.component.scss']
})
export class ContributingComponent implements OnInit {
  isReportGuide: boolean = false;
  isMeasureGuide: boolean = false;
  isShootingGuide: boolean = false;
  isMeasureDiameterGuide: boolean = false;
  isMeasureLengthGuide: boolean = false;
  isMeasureCapacityGuide: boolean = false;
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }


  onMoreview(elementName: string) {
    if (elementName === 'report-guide') { this.isReportGuide = !this.isReportGuide; }
    if (elementName === 'measure-guide') { this.isMeasureGuide = !this.isMeasureGuide; }
    if (elementName === 'shooting-guide') { this.isShootingGuide = !this.isShootingGuide; }
    if (elementName === 'measure-diameter-guide') { this.isMeasureDiameterGuide = !this.isMeasureDiameterGuide; }
    if (elementName === 'measure-length-guide') { this.isMeasureLengthGuide = !this.isMeasureLengthGuide; }
    if (elementName === 'measure-capacity-guide') { this.isMeasureCapacityGuide = !this.isMeasureCapacityGuide; }
  }
}
