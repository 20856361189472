import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';

import {
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatRadioModule,
  MatChipsModule,
  MatStepperModule,
  MatTabsModule,
  MatDividerModule,
  MatSnackBarModule
} from '@angular/material';


export const MATERIAL_MODULES = [
  MatTableModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatInputModule,
  MatRadioModule,
  MatChipsModule,
  MatStepperModule,
  MatSortModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatTabsModule,
  MatDividerModule,
  MatSnackBarModule
];



import { ModelManagerComponent } from './model-manager/model-manager.component';
import { ModelEditDialogComponent } from './model-manager/model-edit-dialog/model-edit-dialog.component';
import { CreateCupComponent } from './create-cup/create-cup.component';
import { AdminSnackbarComponent } from './admin-snackbar/admin-snackbar.component';


export const ENTRY_COMPONENTS = [
  EditDialogComponent,
  ModelEditDialogComponent,
  AdminSnackbarComponent
];




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    MATERIAL_MODULES
  ],
  entryComponents: [
    ENTRY_COMPONENTS
  ],
  declarations: [
    AdminComponent,
    TableComponent,
    CreateCupComponent,
    ModelManagerComponent,
    ENTRY_COMPONENTS
  ],
  exports: [
    AdminComponent,
    CreateCupComponent
  ]
})
export class AdminModule { }
