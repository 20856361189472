import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-snackbar',
  templateUrl: './admin-snackbar.component.html',
  styleUrls: ['./admin-snackbar.component.scss']
})
export class AdminSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
