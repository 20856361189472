import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FilterComponent } from './filter/filter.component';
import { AdminGuard } from './core/admin.guard';
import { UserProfileComponent } from './ui/user-profile/user-profile.component';
import { AdminComponent } from './admin/admin.component';


// page
import { AppComponent } from './app.component';
import { CupsComponent } from './cups/cups.component';
import { CupDetailComponent } from './cup-detail/cup-detail.component';

import { SourceComponent } from './source/source.component';
import { ContributingComponent } from './contributing/contributing.component';



export const routes: Routes = [
  { path: '', redirectTo: '/cups', pathMatch: 'full' },
  { path: 'cups', component: CupsComponent },
  { path: 'detail/:cupImgUrls', component: CupDetailComponent, pathMatch: 'full' },
  { path: 'contributing', component: ContributingComponent },
  { path: 'source', component: SourceComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'filter', component: FilterComponent },

  { path: '**', component: CupsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [AppComponent];
