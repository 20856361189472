import { AdminSnackbarComponent } from './../admin-snackbar/admin-snackbar.component';
import { AppService } from './../../app.service';
import { CupModel } from './../../model/cup';
import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatChipInputEvent, MatSnackBar } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-create-cup',
  templateUrl: './create-cup.component.html',
  styleUrls: ['./create-cup.component.scss']
})
export class CreateCupComponent implements OnInit, AfterViewChecked {

  specs;


  //// Chips ////

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  data: CupModel = {
    state: 'draft',
    index: 0,
    brand: { ko: '메루나', en: 'Me luna' },
    name: { ko: '메루나 클래식 쇼티 S 막대', en: 'Me luna Classic Shorty S Stem' },
    imgUrls: 'meluna_classic_shorty_s_stem',

    size: { ko: '스몰', en: 'Small' },
    colorNames: ['clear', 'purple', 'red'],
    colorCodes: ['#fff', '#9c6bae', '#f05657'],
    country: { ko: '독일', en: 'Germany' },
    price: 15.9,
    priceType: { ko: '유로', en: 'EUR' },
    prodType: { ko: '', en: '' },
    officialsite: 'https://',

    externalDiameter: 38,
    totalLength: 47,
    bodyLength: 35,
    topToHole: 0,

    stemLength: 0,

    stemType: { ko: '고리', en: 'Ring' },
    stemIsNonslip: { ko: '있음', en: 'Y' },

    material: '의료용 실리콘',
    totalCapacity: 0,
    airholeCapacity: 0,
    airholeType: 'Unknown',
    isAirhole: 'Y',

    updatedAt: new Date(),
    createdAt: new Date(),

    cervixType: { ko: '낮은 포궁', en: 'Low Cervix' }
  };


  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.appService
      .getAdminData()
      .subscribe(cups => {
      this.data.index = cups.length++;
    });
    this.appService
      .getSpecs()
      .subscribe(specs => this.specs = specs);
  }


  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(AdminSnackbarComponent, {
      duration: 1500
    });
  }


  /**
   * updateByOptionClick(selectedOption: any, category: any)
   * select 요소 선택(클릭)시 current data에 반영(firestore 업데이트는 아니다)
   * 이 함수 적용시 해당 데이터는 value 뿐 아니라 value_en도 있다고 가정하고 있음(수정가능)
  //  */
  // updateByOptionClick(selectedOption: any, category: any, isSingleAttr?: any) {
  //   this.data[category] = selectedOption.value;
  //   if (!isSingleAttr) {
  //     this.data[category + '_en'] = selectedOption.value_en;
  //   }
  // }

  updateByOption(selectedOption: any, category: any, isSingleAttr?: boolean) {
    // console.log('updateByOption', this.data);
    // console.log('updateByOption selectedOption', selectedOption);
    // 변경하려는 대상 필드가 객체가 아닌 경우 (ex. string, number ...)
    // 현재는 ko만 지정하는데, 나중에 객체형태(ko, en)로 모든 material 사용지점에서 변경해야함.
    if(isSingleAttr) {

      this.data[category] = (category === 'isAirhole')
        ? selectedOption.en 
        : selectedOption.ko;
      
      // this.data[category] = selectedOption.ko;
    } else {
      this.data[category]['ko'] = selectedOption.ko;
      this.data[category]['en'] = selectedOption.en; // en
    }
  }

  addColor(event: MatChipInputEvent, category: string): void {
    const input = event.input;
    const value = event.value;

    if (this.data.colorNames === null) {
      this.data.colorNames = ['clear'];
      this.data.colorCodes = ['#ffffff'];
    }

    const targetArr = (category === 'name')
      ? this.data.colorNames
      : this.data.colorCodes;

    // Add our Cup
    if ((value || '').trim()) {
      targetArr.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeColor(color: any, category: string): void {
    const targetArr = (category === 'name')
      ? this.data.colorNames
      : this.data.colorCodes;
    const index = targetArr.indexOf(color);

    if (index >= 0) {
      targetArr.splice(index, 1);
    }
  }

  createData() {
    this.data.createdAt = new Date(); // 생성일시 추가
    console.log(this.data);

    this.appService.create(this.data)
      .then(() => this.openSnackBar())
      .catch(err => { console.error(err)});
  }
}
