import { MatSort, MatTableDataSource } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { CupModel } from './../model/cup';
import { AppService } from './../app.service';
import { globalAnimations } from 'app/util/social/animations';

export interface SortType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [globalAnimations]
})
export class FilterComponent implements OnInit {
  isInit = false;
  isTagbar = false;

  isFilterIcon = true;
  @ViewChild('snav', { static: false }) snavEl; // sidebar state

  //// table
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<any>;


  

  // array 순서에따라 column 순서가 결정된다.
  displayedColumns = [
    'name',
    'totalLength',
    'totalCapacity',
    'stemType',
    'material',
    'price',
    'country'
  ];
  /// table end

  cups;
  filteredCups;
  specs;

  min = {};
  max = {};

  /// filter-able properties
  filters = {};
  stemTypes = {};

  sliders = {
    totalLength: {
      range: [0, 120],
      min: 0,
      max: 120,
      step: 1
    },
    bodyLength: {
      range: [0, 120],
      min: 0,
      max: 120,
      step: 1
    },
    totalCapacity: {
      range: [0, 50],
      min: 0,
      max: 50,
      step: 1
    }
  };

  cervixRange = {
    low: [this.sliders.totalLength.min, 50],
    mid: [51, 74],
    high: [75, this.sliders.totalLength.max]
  };

  // accordion 스타일 위한 객체
  expansions = {
    totalLength: {
      all: false,
      cervixType: false,
      customBasis: false
    },
    totalCapacity: {
      all: false,
      customBasis: false,
    },
    stemType: true,
    material: true
  };

  isCustomCup = false; // 사용자가 자신의 컵을 선택했는지 여부

  similarOffset = 5; // 유사 범위 지정
  customBasis = {
    totalLength: { cup: null, opt: null },
    bodyLength: { cup: null, opt: null },
    totalCapacity: { cup: null, opt: null },
  };
  // 현재 선택 옵션 표시위한 객체
  currentOptInfo = {
    totalLength: null,
    bodyLength: null,
    totalCapacity: null,
    material: null
  };

  compareOpts = {
    totalLength: [
      { ko: '보다 길이가 짧은 컵', en: 'shorter' },
      { ko: '이랑 길이가 비슷한 컵', en: 'similar' },
      { ko: '보다 길이가 긴 컵', en: 'longer' },
    ],
    bodyLength: [
      { ko: '보다 길이가 짧은 컵', en: 'shorter' },
      { ko: '이랑 길이가 비슷한 컵', en: 'similar' },
      { ko: '보다 길이가 긴 컵', en: 'longer' },
    ],
    totalCapacity: [
      { ko: '보다 용량이 적은 컵', en: 'less' },
      { ko: '이랑 용량이 비슷한 컵', en: 'similar' },
      { ko: '보다 용량이 많은 컵', en: 'more' },
    ]
  };

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
    this.appService
      .getSpecs()
      .subscribe(specs => {
        this.specs = specs;
        this.init_active_attr();
      });
    this.appService
      .getData()
      .subscribe(cups => {
        this.cups = cups;
        this.init_slider(cups, 'totalLength');
        this.init_slider(cups, 'totalCapacity');
        this.applyFilters('init');
        this.updateDataSource();
    });
  }

  private applyFilters(from?: string) {
    this.isInit = true;
    window.scrollTo(0, 0);
    this.filteredCups = _.filter(this.cups, _.conforms(this.filters));
    if (from !== 'init') {
      this.isTagbar = true;
      this.filter_stemType();
      this.filter_material();
    }
    this.updateDataSource();
  }
  snavStateChange(state: string) {
    this.isFilterIcon = (state === 'open') ? false : true;
    // console.log(this.isFilterIcon);
  }
  openModal(infoName: string) {
    // console.log('openModal in FilterComponent');
  }


  // mat-table의 dataSource를 업데이트(sort 포함)
  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.filteredCups);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name.ko;
        case 'stemType':
          return item.stemType.ko;
        default:
          return item[property];
      }
    }
    this.dataSource.sort = this.sort;
  }

  init_active_attr() {
    // console.log('init_active_attr', this.specs);
    const isActiveSpecs = [
      'materials',
      'cervixTypes',
      'stemTypes',
      'stemIsNonslips',
      'isAirholes'
    ];
    _.forEach(isActiveSpecs, specName => {
      _.forEach(this.specs[specName].values, o => { o['active'] = false; });
    });
  }

  filter_capacity() {
    this.clear_customBasis('totalCapacity');
    this.filterBetween('totalCapacity', this.sliders.totalCapacity.range);
    this.currentOptInfo.totalCapacity = '전체 용량 '
      + this.sliders.totalCapacity.range[0] + 'ml ~ '
      + this.sliders.totalCapacity.range[1] + 'ml';
  }

  filter_length(from: string, opt?: any) {
    // console.log('filter_length from', from);
    
    // this.clear_optionBySection('cervixTypes');
    // this.clear_customBasis('totalLength');
    // this.clear_currentOptInfo('totalLength');
    // this.init_slider(this.cups, 'totalLength');

    switch (from) {
      case 'slider':
        // reset
        this.clear_optionBySection('cervixTypes');
        this.clear_customBasis('totalLength');
        // update
        this.filterBetween('totalLength', this.sliders.totalLength.range);
        this.currentOptInfo.totalLength = '전체 길이 '
          + this.sliders.totalLength.range[0] + 'mm ~ '
          + this.sliders.totalLength.range[1] + 'mm';
        break;
      case 'cervixType':
        // reset
        this.clear_customBasis('totalLength');
        const cervixTypes: any = this.specs.cervixTypes.values;
        // update
        const selectedIndex = _.findIndex(cervixTypes, (o) => { return o === opt });
        // 선택된 컵이 있는 경우
        if (selectedIndex !== -1) {
          // 기존 선택을 해제하는 경우
          if (cervixTypes[selectedIndex].active) {
            // reset
            _.forEach(cervixTypes, cervix => { cervix['active'] = false; });
            this.clear_currentOptInfo('totalLength');
            // update
            // 선택 0 이기에, 길이 옵션 해제하고, 전체 범위로 필터링 시작
            this.init_slider(this.cups, 'totalLength');
            this.filterBetween('totalLength', this.sliders.totalLength.range);
            this.applyFilters();
          } else { // 새로운 옵션을 선택하는 경우
            // reset
            _.forEach(cervixTypes, cervix => { cervix['active'] = false; }); // 모든 옵션 해제
            cervixTypes[selectedIndex].active = !cervixTypes[selectedIndex].active; // 선택 옵션 활성화
            const cervixName = cervixTypes[selectedIndex].ko;
            this.sliders.totalLength.range = this.getCervixRangeByName(cervixName);
            this.filterBetween('totalLength', this.sliders.totalLength.range);
            this.currentOptInfo.totalLength = cervixName;
          }
        }
        break;
      case 'custom-cup':
        // reset
        this.clear_optionBySection('cervixTypes');
        // update
        // 선택 컵 & 옵션으로 범위 얻기
        const range =
          this.getSliderRangeByCustomBasis(
            'totalLength',
            this.customBasis.totalLength.cup,
            this.customBasis.totalLength.opt);
        // 선택 컵 기준 범위로 슬라이더 범위 변경
        this.sliders.totalLength.range = range;
        // 선택 정보 업데이트
        this.currentOptInfo.totalLength =
          this.customBasis.totalLength.cup.name.ko + this.customBasis.totalLength.opt;
        this.applyFilters();
        break;
      default:
        console.error('filter_length');
        break;
    }
  }

  // 여기서는 filterSpec의 active status를 변경만 한다.
  setOptions(property: string, opt: any) {
    const path = this.specs[property].values;
    const index = _.findIndex(path, o => { return o === opt });
    path[index]['active'] = !path[index]['active'];
    // material, stemType에 대한 필터는 applyFilters() 이후 매번 실행
    this.applyFilters();
  }

  getSliderRangeByCustomBasis(sectionName: string, cup: CupModel, opt: any) {
    const dataMin: any = _.minBy(this.cups, sectionName);
    const dataMax: any = _.maxBy(this.cups, sectionName);
    const _min: number = +dataMin[sectionName];
    const _max: number = +dataMax[sectionName];

    let min: number;
    let max: number;

    const basis: number = +cup[sectionName];

    if (sectionName === 'totalLength' || sectionName === 'bodyLength') {
      switch (opt.en) {
        case 'shorter':
          min = _min;
          max = basis;
          break;
        case 'similar':
          min = (basis - this.similarOffset)
          max = (basis + this.similarOffset);
          if (min < _min) { min = _min; }
          if (max > _max) { max = _max; }
          break;
        case 'longer':
          min = basis;
          max = _max;
          break;
        default:
          console.error('getSliderRangeByCustomCup');
          min = _min;
          max = _max;
          break;
      }
    } else if (sectionName === 'totalCapacity') {
      switch (opt.en) {
        case 'less':
          min = _min;
          max = basis;
          break;
        case 'similar':
          min = (basis - this.similarOffset)
          max = (basis + this.similarOffset);
          if (min < _min) { min = _min; }
          if (max > _max) { max = _max; }
          break;
        case 'more':
          min = basis;
          max = _max;
          break;
        default:
          console.error('getSliderRangeByCustomCup');
          min = _min;
          max = _max;
          break;
      }
    }

    return [+min, +max];
  }

  getCervixRangeByName(cervixTypeNameKo: string) {
    let range;
    switch (cervixTypeNameKo) {
      case '낮은 포궁':
        range = this.cervixRange.low;
        break;
      case '중간 포궁':
        range = this.cervixRange.mid;
        break;
      case '높은 포궁':
        range = this.cervixRange.high;
        break;
      default:
        console.error('getCervixRangeByName() error');
        break;
    }
    return range;
  }


  filter_material() {
    const optArr: any = this.specs.materials.values;
    const optArr_selected = _.filter(optArr, o => { return o.active; });

    if (optArr_selected.length === 0 || optArr_selected.length === optArr.length) {
      // 선택 수가 0이거나, 모두 선택인 경우 필터하지 않음
    } else {
      let multiOptsArr = [];
      _.forEach(optArr_selected, selectedMaterial => {
        // selectedMaterial['ko'] 형태로하면 안된다.
        const eachOptResult = _.filter(this.filteredCups, _.conforms({ 'material': (n) => { return n === selectedMaterial['ko']; } }));
        multiOptsArr = _.concat(multiOptsArr, eachOptResult);
      });
      this.filteredCups = multiOptsArr;
    }
  }


  filter_stemType() {
    if (!this.specs) { console.error('spec is undefined'); }
    const stemTypes = this.specs.stemTypes.values;
    const selectedStemTypes = _.filter(stemTypes, o => { return o.active; });

    if (selectedStemTypes.length === 0 || selectedStemTypes.length === stemTypes.length) {
      // 선택하지 않거나, 전체 선택일 경우 필터링 작업하지 않음
    } else {
      let multiOptsArr = [];
      _.forEach(selectedStemTypes, selectedStemType => {
        const eachOptResult =
          _.filter(this.filteredCups,
          _.conforms({ 'stemType': (n) => { return n.ko === selectedStemType['ko']; } }));
        multiOptsArr = _.concat(multiOptsArr, eachOptResult);
      });
      this.filteredCups = multiOptsArr;
    }
  }

  // 특정 컵 선택
  setCustomBasisCup(property: string, cup: any) {
    this.customBasis[property].cup = (cup === 'reset') ? null : cup;
    if (this.customBasis[property].cup && this.customBasis[property].opt) {
      const currentCup = this.customBasis[property].cup[property];
      const currentOpt = this.customBasis[property].opt['en'];
      this.filterComparison(property, currentCup, currentOpt);
    }
  }
  // 특정 옵션 선택
  setCustomBasisOpt(property: string, opt: any) {
    this.customBasis[property].opt = opt;
    if (this.customBasis[property].cup && this.customBasis[property].opt) {
      const currentCup = this.customBasis[property].cup[property];
      const currentOpt = this.customBasis[property].opt['en'];
      this.filterComparison(property, currentCup, currentOpt);
      // 스탯바 업데이트
      this.currentOptInfo[property] =
        this.customBasis[property].cup.name.ko + ' ' + this.customBasis[property].opt.ko;
    }
  }


  init_slider(arr, property: string) {
    if (arr) {
      const min = _.minBy(arr, property);
      const max = _.maxBy(arr, property);

      this.min[property] = +min[property];
      this.max[property] = +max[property];

      this.sliders[property].min = this.min[property];
      this.sliders[property].max = this.max[property];

      this.sliders[property].range = [this.min[property], this.max[property]];
    } else {
      console.error('init_slider() !arr');
    }
  }

  filterExact(property: string, rule: any) {
    this.filters[property] = val => val === rule;
    this.applyFilters();
  }

  /// filter  numbers greater than rule
  filterGreaterThan(property: string, rule: number) {
    console.log('this.filters before', this.filters);
    this.filters[property] = val => val > rule;
    console.log(this.filters);
    this.applyFilters()
  }

  /// filter between rule
  filterBetween(property: string, rule: any) {
    this.filters[property] = val => val >= rule[0] && val <= rule[1];
    this.applyFilters();
  }

  /// filter comparison
  filterComparison(property: string, rule: number, opt: string) {
    // console.log(property + ', ' + rule + ', ' + opt);
    let uniOpt;
    if (opt === 'shorter' || opt === 'less') {
      uniOpt = 'less';
    } else if (opt === 'longer' || opt === 'more') {
      uniOpt = 'more';
    } else if (opt === 'similar') {
      uniOpt = opt;
    } else {
      console.error('filtercomparison()');
    }

    if (property === 'totalLength') {
      this.clear_optionBySection('cervixTypes');
    }

    switch (uniOpt) {
      case 'less':
        this.filters[property] = val => val < rule;
        this.sliders[property].range = [+this.min[property], +rule];
        this.applyFilters();
        break;
      case 'similar':
        const similarRange = [];
        const similarMin = (+rule - this.similarOffset);
        const similarMax = (+rule + this.similarOffset);
        similarRange[0] = (similarMin < this.min[property]) ? this.min[property] : similarMin;
        similarRange[1] = (similarMax > this.max[property]) ? this.max[property] : similarMax;
        this.filters[property] = val => val >= similarRange[0] && val <= similarRange[1];
        this.sliders[property].range = similarRange;
        this.applyFilters();
        break;
      case 'more':
        this.filters[property] = val => val > rule;
        this.sliders[property].range = [+rule, this.max[property]];
        this.applyFilters();
        break;
      default:
        console.error('filterComparison');
        break;
    }
    this.applyFilters();
  }
  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) {
      this.removeFilter(property);
    } else {
      this.filters[property] = val => val
      this.applyFilters()
    }
  }
  //// clear

  // 특정 컵 선택 시
  clear_customBasis(property: string) {
    this.customBasis[property].cup = null;
    this.customBasis[property].opt = null;
  }
  clear_optionBySection(sectionName?: string) {
    // totalLength로 들어오는 경우 totalLengths.
    switch (sectionName) {
      case 'totalLength':
        // totalLength로 오면 cervixTypes
        _.forEach(this.specs.cervixTypes.values, o => { o['active'] = false; });
        break;
      case 'bodyLength':
        break;
      case 'totalCapacity':
        break;
      case 'stemType':
        _.forEach(this.specs.stemTypes.values, o => { o['active'] = false; });
        break;
      case 'materials':
        _.forEach(this.specs.materials.values, o => { o['active'] = false; });
        break;
      default:
        // 해당 없는 경우
        _.forEach(this.specs[sectionName].values, o => { o['active'] = false; });
        break;
    }
  }

  // totalLength: null,
  // bodyLength: null,
  // totalCapacity: null,
  // material: null
  clear_currentOptInfo(property: string) {
    if (property === 'all') {
      _.forEach(this.currentOptInfo, (value, key) => { this.currentOptInfo[key] = null; })
    } else {
      this.currentOptInfo[property] = null;
    }
  }
  // tagbar에서 '전체 재설정' 클릭하여 모든 설정 초기화 할 때
  clear_all() {
    this.clear_currentOptInfo('all');
    this.init_active_attr();
    this.init_slider(this.cups, 'totalLength');
    this.init_slider(this.cups, 'totalCapacity');
    this.filteredCups = this.cups;
    // update mat-table source
    this.updateDataSource();
  }

  /// tagbar에서 특정 조건 하나씩 삭제할 때
  removeFilter(property: string) {
    delete this.filters[property];
    this[property] = null;
    this.clear_currentOptInfo(property);
    this.clear_optionBySection(property); // 다중 조건 섹션은 사용하지 않음
    this.init_slider(this.cups, property);
    this.applyFilters();
  }


  getStemTypeImageUrl(stemTypeEn: string) {
    let url = 'assets/images/stemTypes/';
    if (stemTypeEn) {
      url +=  _
      .chain(stemTypeEn)
      .replace(' ', '_')
      .toLower()
      .value();
    } else {
      url += 'etc';
    }
    return url += '@2x.png';
  }


  gotoDetail(cupImgUrls: string) {
    const link = ['/detail', cupImgUrls];
    this.router.navigate(link);
  }

  // table
  trackByUid(index, item) {
    return item.uid;
  }
}
