import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule, MatFormFieldModule, MatTableModule, MatPaginatorModule } from '../../../node_modules/@angular/material';
import { SourceComponent } from './source.component';


const SOURCE_MATERIALMODULES = [
  MatExpansionModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule
];

@NgModule({
  imports: [
    CommonModule,
    SOURCE_MATERIALMODULES
  ],
  declarations: [
    SourceComponent
  ]
})
export class SourceModule { }
