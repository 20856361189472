import { AppService } from './../../../app.service';
import { Component, OnInit, Inject, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
// form
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

//// Angular Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-model-edit-dialog',
  templateUrl: './model-edit-dialog.component.html',
  styleUrls: ['./model-edit-dialog.component.scss']
})
export class ModelEditDialogComponent implements OnInit, AfterViewChecked {

  newModel = { ko: null, en: null };
  updateSource;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModelEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('model-edit-dialog', this.data);
    this.updateSource = this.data;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  addSpecItem() {
    this.updateSource.values
      .push({ ko: this.newModel.ko, en: this.newModel.en });
  }
  deleteSpecItem(index: number) {
    this.updateSource.values
      .splice(index, 1);
  }

  updateSpec() {
    const ref = {};
    ref[this.updateSource.name] = this.updateSource;

    this.appService
      .updateSpec(ref)
      .then(() => this.dialogRef.close())
      .catch(err => console.error(err));
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
