import { trigger, transition, query, style, stagger, animate } from '@angular/animations';

// step 3: 독립운동가 선택 페이지 > hero option List Trigger (enter only)
export const gridAnimation = trigger('gridAnimation', [
    // each time the binding value changes
    // 바인딩 된 값이 변경될 때마다
    transition('* => *', [
      query(':enter', [
        style({ opacity: 0 }),
        stagger(100, [
          animate('.6s 1s', style({ opacity: 1 }))
        ])
      ], { optional: true })
    ])
]);


export const ngIfTrigger = trigger('ngIfTrigger', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s', style({ opacity: 1 })),
  ])
]);


// NgIf Trigger (enter only)
export const ngIfTriggerInOut = trigger('ngIfTriggerInOut', [
  transition(':enter', [
    style({ 
      opacity: 0,
      transform: 'translate(0, 4px)',
    }),
    animate('.5s .5s ease-in-out', style({ 
      opacity: 1,
      transform: 'translate(0, 0)'
    })),
  ]),
  transition(':leave', [
    style({ 
      opacity: 1,
      transform: 'translate(0, 0)',
    }),
    animate('.5s ease-in-out', style({ 
      opacity: 0,
      transform: 'translate(0, 4px)',
    })),
  ]),
]);
export const globalAnimations = [
	gridAnimation,
	ngIfTrigger,
  ngIfTriggerInOut
];
