import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatButtonModule,
  MatChipsModule,
  MatOptionModule,
  MatSelectModule,
  MatExpansionModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatToolbarModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule
} from '@angular/material';



const FILTER_MATERIAL_MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatDividerModule,
  MatButtonModule,
  MatChipsModule,
  MatExpansionModule,
  MatTooltipModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatToolbarModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule
];

@NgModule({
  imports: [
    CommonModule,
    FILTER_MATERIAL_MODULES
  ],
  declarations: [ ],
  exports: [
    FILTER_MATERIAL_MODULES
  ]
})
export class FilterMaterialModule { }
