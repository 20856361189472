import { CupModel } from './../../model/cup';
import { EditDialogComponent } from './../edit-dialog/edit-dialog.component';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';

import { AppService } from './../../app.service';
import * as _ from 'lodash';



@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
  cups;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  // array 순서에따라 column 순서가 결정된다.
  displayedColumns = [
    'brand',
    'prodType',
    'size',
    'name',
    'externalDiameter',
    'totalLength',
    'bodyLength',
    'stemLength',
    'totalCapacity',
    'airholeCapacity',
    'stemType',
    'stemIsNonslip',
    'country',
    'price',
    'isAirhole',
    'officialsite',
    'material',
    'imgUrls',
    'state',
    'edit'
  ];
  constructor(
    private afs: AngularFirestore,
    public dialog: MatDialog,
    private appService: AppService) {
    }

  ngOnInit() { 
    
  }


  ngAfterViewInit() {
    this.appService
      .getAdminSnapshot()
      .subscribe(data => {
        const sortedData = _.sortBy(data,  [function(o){ return o.name.en; }]);
        this.dataSource = new MatTableDataSource(sortedData);
        this.dataSource.sort = this.sort;
        this.cups = sortedData;
      });
  }

  setFields() {
    console.log('setFields');

    _.forEach(this.cups, cup => {
      const id = cup.id;
      const totalLength = +cup.totalLength;
      // console.log(isAirhole_kr);
      const updateData = { 'totalLength': totalLength };
      this.appService.setCupWithMerge(id, updateData);
    })
  }


  applyFilter(filterValue: string) {
    console.log('applyFilter filterValue', filterValue);


    filterValue = filterValue.trim().toLowerCase();
    console.log('applyFilter after', filterValue);
    this.dataSource.filter = filterValue;
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '100vw',
      data: data
    });
  }

  openUrl(targetUrl: string) {
    window.open(targetUrl);
  }

  trackByUid(index, item) {
    return item.uid;
  }



}
