import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavComponent } from './nav/nav.component';
// import {MatAutocompleteModule} from '@angular/material/autocomplete'; 

import {
  MatIconModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatOptionModule,
  MatInputModule,
} from '@angular/material';

export const UI_COMPONENTS = [
  FooterComponent,
  UserProfileComponent,
  NavComponent
];

export const UI_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatIconModule,
  MatFormFieldModule,
  MatOptionModule,
  MatInputModule,
];

@NgModule({
  declarations: [
    UI_COMPONENTS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UI_MODULES
  ],
  exports: [ UI_COMPONENTS, UI_MODULES ]
})
export class UiModule { }
