import { Component, OnInit } from '@angular/core';
import { FacebookService, UIParams, UIResponse } from 'ngx-facebook';
import { Router } from '@angular/router';

@Component({
  selector: 'app-util-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  shareMsg = '모여라 월경컵';
  url = 'https://cups.kr';
  contentUrl: string;

  constructor(
      private fb: FacebookService,
      private router: Router
      ) {
      this.contentUrl = router.url;

      fb.init({
          appId: '640680072797067',
          version: 'v2.9'
      });
    }

  ngOnInit() { }

  share(url: string) {
      const targetUrl = 'https://cups.kr' + this.router.url;
      const params: UIParams = {
              method: 'share',
              display: 'popup',
              href: targetUrl,
      };
      this.fb.ui(params)
          .then((res: UIResponse) => console.log(res))
          .catch((e: any) => console.error(e));
  }


  socialShare(sns: string) {
    let openUrl: string;

    switch (sns) {
        case 'facebook':
            openUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
            break;
        case 'twitter':
            openUrl = 'https://twitter.com/intent/tweet?text=' + this.shareMsg + this.url;
            break;
        default:
            openUrl = 'none';
            break;
    }
    // check each sns share url.
    (openUrl === 'none')
        ? location.reload()
        : window.open(openUrl);
  }


   /**
   * This is a convenience method for the sake of this example project.
   * Do not use this in production, it's better to handle errors separately.
   * @param error
   */
  private handleError(error) {
    console.error('Error processing action', error);
  }
}
