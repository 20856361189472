// import {
//   MatTooltipModule,
//   MatToolbarModule,
//   MatIconModule,
//   MatButtonModule,
//   MatDividerModule,
//   MatAutocompleteModule,
//   MatInputModule,
//   MatOptionModule,
//   MatFormFieldModule,
//   MatSidenavModule,
//   MatListModule
// } from '@angular/material';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// firebase
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment.prod';

// custom module
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AdminModule } from './admin/admin.module';

// component
import { AppComponent } from './app.component';
import { CupsComponent } from './cups/cups.component';
import { CupDetailComponent } from './cup-detail/cup-detail.component';
import { CupSliderComponent } from './cup-detail/cup-slider/cup-slider.component';



// Loader
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  POSITION, SPINNER, PB_DIRECTION
} from 'ngx-ui-loader';


export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // foreground spinner
  fgsSize: 40,
  fgsType: SPINNER.pulse,
  fgsColor: 'hsl(348, 100%, 61%)',
  // progress bar (top)
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 4, // progress bar thickness
  pbColor: 'hsl(348, 100%, 61%)',
  // overlay (full)
  overlayColor: 'rgb(247, 247, 247)'
};





// Service
import { AppService } from './app.service';


// Feature
import { SearchComponent } from './search/search.component';
import { CustomSearchPipe } from './search/custom-search.pipe';
import { OrderPipe } from './pipes/order.pipe';

// UI
import { FooterComponent } from './ui/footer/footer.component';
import { UserProfileComponent } from './ui/user-profile/user-profile.component';


// Util
import { SocialComponent } from './util/social/social.component';


// Lib
import { FacebookModule } from 'ngx-facebook';


// custom
import { FilterModule } from './filter/filter.module';

import { ContributingModule } from './contributing/contributing.module';
import { SourceModule } from './source/source.module';
import { UiModule } from './ui/ui.module';
import { CupDetailModule } from './cup-detail/cup-detail.module';





// export const MATERIAL_MODULES = [
//   MatTooltipModule,
//   MatToolbarModule,
//   MatIconModule,
//   MatButtonModule,
//   MatDividerModule,
//   MatAutocompleteModule,
//   MatInputModule,
//   MatOptionModule,
//   MatFormFieldModule,
//   MatSidenavModule,
//   MatListModule
// ];



@NgModule({
  declarations: [
    AppComponent,
    CupsComponent,
    SearchComponent,
    CustomSearchPipe,
    OrderPipe,
    // FooterComponent,
    SocialComponent,
    // UserProfileComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,

    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // Angular Firebase
    AngularFireModule.initializeApp(environment.firebaseConfig),
    

    CoreModule,
    AdminModule,
    FilterModule,
    ContributingModule,
    SourceModule,
    UiModule,
    CupDetailModule
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
