import { CupModel } from './../model/cup';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchService]
})
export class SearchComponent implements OnInit {
  // cups;
  cups: Observable<CupModel[]>;
  private searchTerms = new Subject<any>();

  isResult: boolean;

  key = '';
  field = 'name';
  ignoreCase = false;


  search(term: string): void {
    this.searchTerms.next(term);
  }

  constructor(private searchS: SearchService) { }

  ngOnInit(): void {
    this.cups = this.searchTerms
      .distinctUntilChanged()
      .switchMap(term => term ? this.searchS.search(term) : Observable.of<CupModel[]>([]))
      .catch(error => {
        // TODO: real error handling
        console.log(error);
        return Observable.of<CupModel[]>([]);
    });
  }


  // Relative Content Navigate
  getTargetURL(imgUrls: string) {
    return '/detail/' + imgUrls;
  }

}
