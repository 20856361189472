import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { CupModel } from 'app/model/cup';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isMobileNavState = false;
  isSearch = false;

  cupCtrl: FormControl;
  searchedCups: Observable<CupModel[]>;
  cups: CupModel[];

  constructor(
    private appService: AppService,
    private router: Router
    ) { }

  ngOnInit() {
    this.cupCtrl = new FormControl();
    this.appService
    .getData()
    .subscribe(cups => {
      this.cups = cups;
    });

    this.searchedCups = this.cupCtrl.valueChanges
      .pipe(
        startWith(''),
        map(cup => cup ? this.searchCups(cup) : this.cups.slice()));
  }

  searchCups(name: any) {
    // 한,영 모두 지원하는 형태로 개선 필요
    return this.cups.filter(cup => cup.name.ko.indexOf(name) === 0);
  }


  gotoPage(cupImgUrls: string): void {
    this.isSearch = false;
    this.isMobileNavState = false;
    this.router.navigate([`/detail/${cupImgUrls}`]);
  }
  
  onSearch() {
    this.isSearch = !this.isSearch;
  }

  setMobileNavState(action?: string) {
    if (action) {
      this.isMobileNavState = (action === 'close') ? false : true;
    } else {
      this.isMobileNavState = !this.isMobileNavState;
    }
  }

  onBackdrop() {
    this.isSearch = false;
    this.isMobileNavState = false;
  }

}
